import React, { FormEvent, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './login-form.css';
import useLocaleContext from '@/hooks/use-locale-context';
import Button from '@/components/button';
import { TextField } from '@/components/form-field/form-field';
import { LoginMutationVariables } from '@/graphql/generated';

type Props = {
    formError?: string;
    handleSubmit: (event: any) => void;
    loading?: boolean;
};

const Login = ({ formError, handleSubmit, loading }: Props) => {
    const { t, i18n } = useTranslation();
    // const { t } = useLocaleContext();
    const [formData, setFormData] = useState<LoginMutationVariables>({
        email: '',
        password: '',
    });

    function doSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (event.currentTarget.reportValidity()) {
            handleSubmit(formData);
        }
    }

    return (
        <form
            data-testid="login"
            className={classNames(styles.root)}
            onSubmit={doSubmit}
        >
            <legend className="h1 xl semibold-weight">{t`loginToYourAccount`}</legend>
            {formError && (
                <p data-testid="form-error" className="form-error-message">
                    {formError}
                </p>
            )}
            <TextField
                label={t`Account`}
                placeholder={t`entryAccount`}
                autoComplete="on"
                required
                validationMessages={{
                    valueMissing: t`missingAccount`,
                    typeMismatch: t`IncorrectAccount`,
                }}
                value={formData.email}
                alt
                onChange={event => {
                    setFormData({ ...formData, email: event.target.value });
                }}
            />
            <TextField
                label={t`password`}
                placeholder={t`entryPassword`}
                required
                showPasswordToggle
                validationMessages={{
                    valueMissing: t`missingPassword`,
                }}
                type="password"
                value={formData.password}
                alt
                onChange={event => {
                    setFormData({
                        ...formData,
                        password: event.target.value,
                    });
                }}
            />
            <Button
                big
                className={styles.loginSubmit}
                loading={loading}
                type="submit"
            >{t`login`}</Button>
        </form>
    );
};

export default Login;
