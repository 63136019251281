import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import { applyResultTransforms } from 'graphql-tools/dist/transforms/transforms';
import { useTranslation } from 'react-i18next';
import {
    LoginMutationVariables,
    Location,
    useLoginMutation,
    GetUserDocument,
    GetUserQuery,
    useSendRegistrationMailMutation,
    useSendPasswordRecoveryEmailMutation,
    SendRegistrationMailMutationVariables,
} from '@/graphql/generated';
import history from '@/router/history';
// import {home, registration } from '@/router/urls';
import urls from '@/router/urls';
import useLocaleContext from '@/hooks/use-locale-context';
import styles from './login.css';
import logo from '@/assets/images/logo-linrui-full.svg';
import LoginForm from '@/components/login-form';
import loginIllustration from '@/assets/images/login-image.png';
import useDocumentTitle from '@/hooks/use-document-title';
import RequestRegistrationModal from '@/components/request-registration-modal/request-registration-modal';
import Button from '@/components/button';
import useMediaQuery from '@/hooks/use-media-query';
import getGraphlErrorCode from '@/util/get-graphql-error-code';
import useToastContext from '@/hooks/use-toast-context';
import useGlobalState from '@/hooks/use-global-state';

type PartialLocation = Pick<Location, 'id' | 'name'>;

const usePreloadUser = () => {
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);

    const preloadUser = () => {
        setLoading(true);

        return client
            .query<GetUserQuery>({
                query: GetUserDocument,
                fetchPolicy: 'network-only',
            })
            .then(() => setLoading(false));
    };

    return {
        preloadUser,
        loading,
    };
};

const Login = ({ location }: RouteComponentProps) => {
    const { t, i18n } = useTranslation();
    const [, dispatch] = useGlobalState();
    const loginErrorToText: TranslationMap<LoginErrors> = {
        USER_DOES_NOT_EXISTS: msg => t`notRecogniseAccount`,
        USER_OR_PASSWORD_WRONG: msg => t`errorAccountOrPassword`,
        USER_BLOCKED: msg => t`blockSomeMinutes`,
        UNKNOWN: msg => t`somethingWrongRetry`,
    };

    const requestErrorToText: TranslationMap<RequestRegistrationErrors> = {
        USER_DOES_NOT_EXISTS: msg => t`notRecogniseAccount`,
        ERROR_USER_EMAIL_WRONG_FORMAT: msg => t`errorEmail`,
        UNKNOWN: msg => t`somethingWrongRetry`,
    };

    useDocumentTitle(t`login`);
    const { clearToasts } = useToastContext();

    const [login, { loading: loadingLogin }] = useLoginMutation();
    const { preloadUser, loading: loadingUser } = usePreloadUser();

    const [loginFormError, setLoginFormError] = useState('');
    const [requestFormError, setRequestFormError] = useState('');
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [
        showRegistrationConfirmation,
        setShowRegistrationConfirmation,
    ] = useState(false);
    const [
        sendRegistrationMail,
        { loading: loadingRegistration },
    ] = useSendRegistrationMailMutation();

    const [
        sendPasswordRecoveryEmail,
        { loading: loadingPasswordRecovery },
    ] = useSendPasswordRecoveryEmailMutation();

    const isLarge = useMediaQuery('large');

    useEffect(() => {
        clearToasts();
    }, [clearToasts]);

    const onLoginClick = ({ email, password }: LoginMutationVariables) => {
        login({
            variables: {
                email,
                password,
            },
        })
            .then(result => {
                const loc = result.data!.login;

                localStorage.setItem('loginUser', email);

                if (String(loc?.id) === 'undefined') {
                    history.replace(urls.dashboard.allLocations);
                } else {
                    if (loc?.id !== undefined) {
                        dispatch({ type: 'setRoomId', value: loc?.id });
                    }
                    history.replace(
                        urls.dashboard.singleLocation({
                            locationId: String(loc?.id),
                        })
                    );
                }
            })
            .catch(e => {
                const errorCode = getGraphlErrorCode(
                    e,
                    loginErrorToText
                ) as LoginErrors;
                setLoginFormError(loginErrorToText[errorCode](t));
            });
    };

    const onRequestSubmit = async (
        formData: SendRegistrationMailMutationVariables
    ) => {
        sendPasswordRecoveryEmail({
            variables: {
                email: formData.email,
                language: navigator.language,
            },
        })
            .then(() => {
                setShowRegistrationConfirmation(true);
            })
            .catch(e => {
                const code = getGraphlErrorCode(
                    e,
                    requestErrorToText
                ) as RequestRegistrationErrors;
                setRequestFormError(requestErrorToText[code](t));
            });
        return false;
    };

    return (
        <div className={styles.root}>
            {isLarge && (
                <div className={styles.left}>
                    <img
                        className={styles.illustration}
                        src={loginIllustration}
                        alt=""
                    />
                    <h1 className={styles.texttitile}>{t`CMND.app.title`}</h1>
                    <a
                        className={styles.textbody}
                        href="https://www.we-call.cn:8443"
                    >{t`CMND.app.link`}</a>
                </div>
            )}
            <div className={styles.right}>
                <div className={styles.logoSpace}>
                    <span className={styles.logo} title={t`CMND.pro`}>
                        <svg viewBox={logo.viewBox}>
                            <use xlinkHref={logo.url} />
                        </svg>
                    </span>
                </div>
                <LoginForm
                    handleSubmit={onLoginClick}
                    formError={loginFormError}
                    loading={loadingLogin || loadingUser}
                />
                <p className={styles.registerRequest}>
                    {t`haveNotAccount`}
                    <Button
                        unstyled
                        onClick={() => {
                            history.replace(urls.registration);
                        }}
                    >
                        <span className={styles.link}>{t`registerHeer`}</span>
                    </Button>

                    {t`forgetPassword`}
                    <Button
                        unstyled
                        onClick={() => {
                            setShowRegistrationModal(true);
                        }}
                    >
                        <span className={styles.link}>{t`clickHere`}</span>
                    </Button>
                </p>
                <p className={styles.registerContentProider}>
                    {t`contentRight`}
                    <a href="https://beian.miit.gov.cn">{t`contentProvider`}</a>
                </p>

                <RequestRegistrationModal
                    loading={loadingRegistration}
                    onHide={() => {
                        setRequestFormError('');
                        setShowRegistrationConfirmation(false);
                        setShowRegistrationModal(false);
                    }}
                    formError={requestFormError}
                    setFormError={setRequestFormError}
                    showConfirmation={showRegistrationConfirmation}
                    handleSubmit={onRequestSubmit}
                    show={showRegistrationModal}
                />
            </div>
        </div>
    );
};

export default Login;
